.smalltext {
    padding-top: 5px;
    font-size: 1.8rem;
}

.smalltextnolink {
    color: #252525;
    font-size: 1.8rem;
}

td.smalltextnolink {
    padding-top: 18px;
    padding-right: 20px;
    vertical-align: baseline;
}

.list {
    width: 100%;
}

.bglt,
.bgmd {
    background: none;
}

td.td-right,
td.td-left {
    display: none;
}

.textboldnolink {
    font-size: 2.8rem;
    color: #2f0d6e;
    font-weight: 400;
    line-height: 1.1;
    padding-top: 20px;
}

.smalltextbnolink {
    display: none;
}

.pic {
    display: none;
}