tr#handle_loginMainPortlet > td > table {
    width: 500px;
    margin: 0 auto 120px;
}

.labelSpanEdit img {
    display: none;
}

.greytitle {
    text-align: left;
    padding-bottom: 20px;
    padding-top: 70px;
    .h2;
}

