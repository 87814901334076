input[type="checkbox"].checkbox,
input[type="radio"].radio {
    display: inline;
    margin: 0 4px 0 0;
}

input.input,
input.inputreq,
input[type="text"],
input[type="password"] {
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
    box-shadow: none;
    font-size: 1.8rem;
    resize: vertical;
    border-color: #7e7e7e;
    border-radius: 4px;

    &:focus {
        border-color: transparent;
        box-shadow: 0px 0px 0px 2px @purple;
    }

    &:disabled {
        border: 0;
    }
}

select.input,
select.inputreq,
input.input,
input.inputreq,
input[type=text] {
    margin-bottom: 20px;
}

.bgbutton,
.nlbutton,
input[type="button"] {
    margin-top: 35px;
    .button;
}

select.inputreq,
select.input {
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
    box-shadow: none;
    font-size: 1.8rem;
    resize: vertical;
    border-color: #7e7e7e;
    border-radius: 4px;

    &:focus {
        border-color: transparent;
        box-shadow: 0px 0px 0px 2px @purple;
    }

    &:disabled {
        border: 0;
    }
}

.form-group > label {
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #4e4e4e;
}

.checkbox input[type="checkbox"] {
    & + label::before {
        border-radius: 0;
        border-color: #7e7e7e;
        width: 20px;
        height: 20px;
    }

    &:checked + label::before {
        background-color: @purple;
        border-color: #171818;
    }

    & + label::after {
        color: white;
        width: 19px;
        height: 19px;
        padding-left: 5px;
        padding-top: 3px;
    }

    &:focus + label::before {
        outline: none;
    }
    
    &:disabled + label {
        opacity: 1;
        &::before  {
            background-color: #e7e7e7;
            border: 0;
        }
        &::after  {
            color: #9f9f9f;
        }        
    }
}


.radio input[type="radio"] {
    & + label::before {
        border-color: #7e7e7e;
        width: 20px;
        height: 20px;
    }

    &:checked + label::before {
        border-color: @purple;
        border-width: 6px;
    }

    & + label::after{display: none;}

    &:focus + label::before {
        outline: none;
    }

    &:disabled + label {opacity: 1;}
    &:disabled + label::before  {
        background-color: #e7e7e7;
        border-color: #e7e7e7;
    }
    &:disabled:checked + label::before  {
        background-color: #9f9f9f;
        border-color: #e7e7e7;
    }
    
}

.form-control {
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
    box-shadow: none;
    font-size: 1.8rem;
    resize: vertical;
    border-color: #7e7e7e;

    &:focus {
        border-color: transparent;
        box-shadow: 0px 0px 0px 2px @purple;
    }

    &:disabled {
        border: 0;
    }
    
}

.form-group { 

    .input-group {
        .form-control {
            border-radius: 4px;
        }
        .input-group-btn {
            button {
                background: @primary url("/site/v2/img/footer-signup.png") no-repeat center;
                width: 60px;
                height: 60px;
                border: none;
                margin-left: 10px !important;
                border-radius: 4px !important;
                outline: 0;

                &:hover {
                    background-color: #d14900;
                }

                &.btn-icon {
                    background: @primary;
                    text-align: center;

                    i {
                        color: #FFF;
                        font-size: 4rem;
                    }
                }
            }
        }
    }

    &.has-error{
        .form-control {
            border-color: transparent;
            box-shadow: 0px 0px 0px 2px #ed1c24;
            color: #ed1c24;
            background-color: #f5e6e7;
            .placeholder(#ed1c24);
        }
    }

    &.has-success {
        .form-control {
            border-color: transparent;
            box-shadow: 0px 0px 0px 2px @purple;
        }
    }
}


.selectpicker {
    display: block;
    width: 100%;
    height: 60px;
    padding-left: 16px;
    font-size: 1.8rem;
    border-radius: 4px;
}
.bootstrap-select {
    width: 100% !important;

    .dropdown-toggle {
        height: 60px;
        padding-left: 20px;
        background: white !important;
        box-shadow: none;
        font-size: 1.8rem;
        border-color: #7e7e7e;

        .caret {
            width: 60px;
            height: 60px;
            top: -1px !important;
            right: -1px !important;
            margin: 0 !important;
            border: none;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            .button-background(@primary);
            background-image: url("@{base}/img/arrow-down.png");
        }

        &:focus {
            outline: 0 !important;
        }
    }

    @selected-color: #d14900;
    &.open {
        .dropdown-toggle {
            border-color: transparent !important;
            box-shadow: 0px 0px 0px 2px @purple !important;
            background: inherit !important;

            .caret {
                background-color: @selected-color;
            }

        }
    }

    &:hover .dropdown-toggle .caret{
        background-color: #d14900;
    }

    .dropdown-menu {
        box-shadow: none;
        border-color: #7e7e7e;
        padding-top: 0;
        border-top: 2px solid @purple;
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        li {
            a {
                padding: 15px;
                font-size: 1.8rem;
            }

            &.selected {
                a {
                    background: fade(@primary, 40%);
                }
            }

            &:hover {
                a {
                    background: @primary;
                    color: white;
                }
            }
        }
    }

    &.dropup {
        .dropdown-menu.open {
            padding-top: 5px;
            padding-bottom: 0;
            border-bottom: 2px solid #2f0d6e;
            border-top: 1px solid #7e7e7e;
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
}