.more {
    &-products {
        padding: 20px 0 21px;
        border-bottom: 1px solid #dadada;

        &-text {
            font-size: 2.8rem;
            line-height: 2.1;
            color: @purple;
        }


        @media only screen and (min-width : 1100px) {
            .button {
                letter-spacing: 1.75px;
                padding-left: 54px;
                padding-right: 54px;
            }
        }
        
        @media only screen and (min-width : 993px) {
            &-row {
                & > div:nth-child(1) {
                    text-align: right;
                }
                & > div:nth-child(2) {
                    text-align: left;
                }
            }

            &-text {
                margin-right: 20px;
            }
            .button {
                margin-left: 10px;
            }
        }

        @media only screen and (max-width : 992px) {
            padding: 50px 0 35px;
            &-row {
                margin-bottom: 5px;
            }

            &-text {
                color: @text-color;
                line-height: inherit;
                margin-bottom: 30px;
            }
        }

        @media only screen and (max-width : 767px) {
            padding: 48px 0 33px;

            &-text {
                font-size: 2.46rem;
            }

            .button {
                margin-top: 6px;
                font-size: 2.03rem;
                font-weight: bold;
                letter-spacing: 2.7px;
                padding: 31px 40px;
                border-radius: 10px;
            }
        }
    }
}