.button{
    color: white !important;
    text-transform: uppercase;
    text-decoration: none !important;
    text-align: center;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 500;
    padding: 21px 56px;
    white-space: nowrap;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    border-width: 0;
    .button-background(@primary);

    &-purple {
        .button-background(@purple);
    }

    &.wide {
        min-width: 320px;
    }
}