html {
    @media only screen and (max-width : 992px) {
        
    }

    @media only screen and (max-width : 767px) {
       // font-size: 13px;
    }

    @media only screen and (max-width : 480px) {
        
    }

    @media only screen and (max-width : 320px) {
        
    }
}

body, td, select, textarea, input, button {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

body {
    min-width: 460px;
    margin: 0 !important;
}

#server_commands {
    display: none;
}

main {
    padding-bottom: 120px;
}

img{
    max-width: 100%;
}

button, a, input {
    outline: 0;
    &:active,
    &:focus {
        outline: 0;
    }
}

.list-inline li{
    display: inline;
}

address {
    font-style: normal;
}

.vertical-centering {
    &-container {
        display: table;
        width: 100%;
    }
    &-inner {
        display: table-cell;
        width: 100%;
        vertical-align: middle;
    }
}

#main-content {
    padding-bottom: 120px;
}

.info-block {
    border-radius: 5px;
    background-color: #d2dde8;
    padding: 20px;
    font-size: 1.8rem;
    color: #2f0d6e;
}