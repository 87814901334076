.footer{
    padding-top: 67px;
    min-height: 537px;
    background: #3d3d3d;
    color: white;
    font-size: 1.4rem;

    a {
        color: #fff;
        &:hover {
            text-decoration: none;
            color: darken(#fff, 25%);
        }
    }

    ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
            list-style-type: none;
            margin-bottom: 14px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    h4 {
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 24px;
        color: white;

        &.footer-newsletter-title {
            margin-bottom: 15px;
        }
    }

    &-column {
        margin-bottom: 67px;

        &-bold {
            li {
                font-size: 1.6rem;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 32px;

                &:first-child {
                    margin-top: 30px;
                }
            }
        }
    }

    &-signup {
        &-helper {
            overflow: hidden;
            padding-right: 6px;
        }
        &-input {
            border-radius: 7px;
            border: 1px solid #292057;
            color: black;
            padding: 16px;
            height: 60px;
            width: 100%;
        }
        &-button {
            height: 60px;
            width: 60px;
            float: right;
            padding: 0;
            display: inline-block;
            border: 1px solid #292057;
            border-radius: 7px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("@{base}/img/footer-signup.png");
            &:hover {
                background-image: url("@{base}/img/footer-signup.png");
            }
        }
    }

    &-social {
        margin-top: 46px;
        margin-bottom: 80px;
        margin-bottom: 8vmin;
        font-size: 3.6rem;

        @media only screen and (max-width : 992px) {
            font-size: 6rem;
        }
    }
}
