.specifications {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.8rem;
    text-align: center;
    padding-left: 0;

    li {
        display: block;
        padding: 0px 36px;
        position: relative;
        margin: 20px 0;
    }

    i {
        color: @purple;
        padding-right: 6px;
    }

    &-container {

    }

    &-title {
        color: #252525;
    }

    @media only screen and (min-width : 992px) {
        li {
            display: inline-block;
            padding-bottom: 0;
            margin-bottom: 0;
            & + li:before {
                content: '';
                position: absolute;
                width: 1px;
                height: 15px;
                background: #b9b9b9;
                left: 0;
                top: 5px;
            }
        }
    }
}