#carttable {

    td {
        padding: 11px;
        vertical-align: middle;
        border: none;
        color: #252525;
        background: none;
        font-size: 1.8rem;
    }

    input[type="text"] {
        margin: 0;
    }

    #carttableheader {
        background: #d2dde8;

        td {
            font-size: 1.6rem;
            text-align: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        // hide first and last header cels
        td:first-child div,
        td:last-child div{
            visibility: hidden;
        }
        
        .listheadernosort {
            color: #4f4f4f;
            background: none;
        }
    }
    
    tr.uir-list-row-tr {

        // make odd rows darken
        &.uir-list-row-odd td {
            background: #f5f5f5;
        }

        &.uir-list-row-even td {
            background: #fff;
        }

        td {
            border-bottom: 1px solid #dadada;
        }

        // images cell
        td:first-child {
            text-align: center;

            img {
                width: auto;
            }
        }

    }

    input[type="text"] {
        text-align: center;
        padding: 2px;
        min-width: 100px;
    }

    // beautify trash icon
    .fa-trash-o {
        color: @purple;
        font-size: 2rem;
    }
}

#handle_cartMainPortlet {
    #tbl_checkout,
    #tbl_cancel,
    #tbl_recalc {
        padding-left: 40px;
        display: block;
    }

    td[style='padding-top:5px'], td[style='padding-top: 5px;']{
        >table {
            margin-top: 440px;
        }
    }

    #recalc {
        border-width: 2px !important;
        border-color: transparent;
        margin-top: 33px !important;
    }
}

#handle_cartRelatedItems{
    >td{
        >table{
            display:block;
            position:absolute;
            margin-top:-560px;
            width:968px;
            >tbody{
                >tr{
                    >td{
                        >table{
                            width:968px;
                        }
                    }
                }
            }
        }
    }
}

#__enclosingtable .bgltmedtext {
    background: none;
}

#__enclosingtable .bgltmedtext .medtextboldnolink,
#__enclosingtable .bgltmedtext .text {
    font-size: 11pt;
}

#__enclosingtable{
    margin: 25px auto;
}

@media (min-width: 768px) {
    #__enclosingtable {
        width: 750px;
    }
}
@media (min-width: 992px) {
    #__enclosingtable {
        width: 970px;
    }
}
@media (min-width: 1320px) {
    #__enclosingtable {
        width: 1260px;
    }

    #handle_cartRelatedItems{
        >td{
            >table{
                width:1230px;
                >tbody{
                    >tr{
                        >td{
                            >table{
                                width:1230px;
                            }
                        }
                    }
                }
            }
        }
    }
}