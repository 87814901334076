h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 400;
  line-height: 1.1;
  color: @purple;

  &.white{
    color: white;
  }

  &.dark {
    color: @text-color;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h4, .h4,
h5, .h5,
h6,.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1, .h1 {
  font-size: 5rem;
}
h2, .h2 {
  font-size: 3.6rem;
}
h3, .h3 {
  font-size: 2.4rem;
}

h4, .h4 {
  font-size: 1.8rem;
}

h5, .h5 {
  font-size: 1.4rem;
}
h6, .h6 {
  font-size: 1.2rem;
}

h2.line {
    font-size: 4.5rem;
    text-align: center;
    margin-top: 50px;

    display: table;
    white-space: nowrap;
    &:before, &:after {
        content: '';
        display: table-cell;
        width: 45%;
        background: -webkit-linear-gradient(top, #f26522, #f26522) no-repeat center / 90% 1px;
        background: linear-gradient(to bottom, #f26522, #f26522) no-repeat center / 90% 1px;
    } 
}

h4 {
    margin-top: 30px;
    font-size: 2.8rem;
    text-transform: uppercase;
    color: @text-color;
}

h5 {
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: bold;
}