.introduction {
    .row {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    h3 {
        font-size: 5rem;
    }

    p {
        font-size: 2.2rem;
        line-height: 1.55;
        padding-top: 10px;
    }

    &-link {
        font-size: 1.8rem;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
        padding-top: 1px;
    }

    &-image {
        float: right;
    }

    &-preview {
        @media only screen and (max-width : 991px) {
            text-align: center;
        }
    }

    @media only screen and (max-width : 767px) {
        h3 {
            font-size: 52px;
        }

        p {
            font-size: 2.769rem;
            line-height: 1.45;
            padding-top: 13px;
            padding-bottom: 12px;
        }

        &-link {
            font-size: 2.769rem;
        }
    }

    &-secondary {
        text-align: center;
        font-size: 1.8rem;

        h3 {
            font-size: 3.6rem;
            line-height: 1.15;

            a {
                color: @purple;
                &:hover {
                    color: darken(@purple, 10%);
                }
            }
        }

        p {
            font-size: 1.8rem;
            line-height: 1.6;
            padding-top: 11px;
        }

        div > a {
            font-weight: bold;
            letter-spacing: 1px;
        }

        div > font {
            font-weight: bold;
            letter-spacing: 1px;
            color: @primary;
        }
    }

    &-darken {
        background: #d2dde8;

        hr {
            border-top-color: #e9eef4
        }
    }


    &-product {

        h3 {
            font-size: 3.5rem;
        }

        &-primary {
            h3 {
                font-size: 4.5rem;
            }
        }

        &-image {
            text-align: center;
            .introduction-image {
                float: none;
                margin: auto;
            }
        }

        &-buttons {
            clear: both;
            padding-top: 40px;
            padding-top: 4vmin;
            text-align: center;
        }

        &-button {
            width: 100%;

            &-desktop {
                max-width: 215px;
                margin: auto;
                margin-top: 20px;
                display: block;
            }
        }

        ul {
            font-size: 1.8rem;

            li {
                padding-bottom: 5px;
            }
        } 

        &-separator {
            border-top-color: #d2dde8;
            margin: 0 auto !important;
        }

        &-selector {

            @media only screen and (min-width : 992px) {
                h3 {margin-top: 0;}
            }

            .more {
                list-style-type: none;
                padding-left: 0;
                li {
                    text-transform: uppercase;
                    display: inline;
                    padding: 0 20px;
                    position: relative;

                    &:not(:first-child):before {
                        content: '';
                        width: 1px;
                        position: absolute;
                        height: 15px;
                        background: #b9b9b9;
                        left: -3px;
                        top: 5px;
                        display: inline-block;
                    }

                    a {
                        white-space: nowrap;
                    }

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    
    &-detail {

        &-buy {
            display: inline-block;
        }

        &-price {
            font-size: 4.5rem;
            font-weight: bold;
            color: @purple;
            display: inline-block;
            vertical-align: top;
            height: 60px;
            margin-left: 20px;

            * {
                font-size: inherit !important;
                padding: 0 !important;
                line-height: inherit !important;
                font-weight: inherit;
                color: inherit;
            }
        }

        &-content {

        }

        &-availability {
            p {
                display: block;
                margin-top: 10px;
                font-size: 1.2em !important;
                color: #252525;
                margin: 0;
            }
        }

        h3 {
            font-size: 4.5rem;
        }

        p {
            font-size: 1.8rem;
        }
    }
}