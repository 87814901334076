#paymethhider {
    & > table {
        td {
            vertical-align: baseline;
        }
    }
}

tr#handle_checkoutMainPortlet > td > table {
    width: 870px;
    margin: 0 auto;
}

#handle_checkoutMainPortlet {
    #tbl_submitter {
        padding-right: 40px;
        display: block;
    }

  .resaleframe {
    height: 135px !important;
    padding-left: 0 !important;
    border: 2px solid #4f4f4f;
  }
}

#tbl_fauxsubmit {
    .nlbutton {
        margin-right: 40px;
    }
}

.related-itemlist input.bgbutton {
  margin-top: 5px;
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 400;
  padding: 13px 35px;
  border-radius: 3px;
}
.related-itemlist input.input {
  font-size: 1.3rem;
  padding: 11px 11px;
  margin-bottom: 0;
  height: 34px;
  width: 60px;
}
.related-itemlist .td-item-cell {
  width: 25%;
  padding: 10px 25px;
  font-size: 1.3rem;
}
.related-itemlist .td-item-cell .article-item {
  margin: 0 auto;
  width: 195px;
}
.related-itemlist .related-title {
  background: #d2dde8;
  font-size: 1.6rem;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  padding: 13px 26px;
  vertical-align: middle;
  border: none;
  color: #4f4f4f;
  margin-top: 65px;
}
.related-itemlist .td-item-cell .product_image {
  text-align: center;
}
.add_tables b {
  font-size: 1.4rem;
}
.related-item-desc {
  font-weight: 900;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  width: 195px;
  height: 60px; /* Fallback for non-webkit */
  margin: 0 auto;
  line-height: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#handle_cartRelatedItems input.bgbutton{
  margin-top: 5px;
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 400;
  padding: 13px 35px;
  border-radius: 3px;
}

#handle_cartRelatedItems strong.prod_name{
  color:#4f4f4f;
}


.triangle-obtuse {
  position:relative;
  padding:15px;
  margin:1em 0 -3em;
  color:#8a6d3b;
  font-size:1.7rem;
  background:#fcf8e3;
  border-color: #faf2cc;
  border-width:1px;
  /* css3 */
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;
}

#handle_checkoutMainPortlet  .resaleframe.poframe.tryagain {
  border-color:red;
}
#handle_checkoutMainPortlet  .resaleframe.poframe.uploadsuccess{
  border-color:green;
}

#submitordertable > tr > td, #submitordertable > tbody > tr > td {
  vertical-align: top;
}

#paymentmethodtable tr:first-child td {
  padding-bottom: 2.3rem;
}

.errortext {
  font-size: 10pt;
  color: #EE0000;
  background-color: #FFF4F4;
  padding: 12px 0 0 12px;
}

input[type="text"].tryagain {
  box-shadow: 0 0 0 2px #ee0000;
}