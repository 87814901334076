.header-navigation {
    position: relative;
    
    // shadow
    &:after {
        content: '';
        height: 25px;
        width: 100%;
        position: absolute;
        bottom: 0;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        z-index: 2;
    }

    @media only screen and (min-width : 768px) {
        &:after {
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        }
    }

    &-desktop {

        .navbar {
            @custom-navbar-height: 48px;
            background-color: transparent;
            border-color: transparent;
            margin-bottom: 0;
            border: 0;
            min-height: @custom-navbar-height;

            &-brand {
                padding-top:0px;
                height: @custom-navbar-height;
            }

            &-nav {
                & > li {
                    margin-right: 33px;

                    & > a {
                        text-transform: uppercase;
                        font-size: 2rem;
                        font-weight: bold;
                        padding: 14px 20px;
                        color: @text-color;
                        letter-spacing: 2px;
                    }

                    &.active a,
                    &.open a {
                        color: white;
                        background-color: @purple;
                    }

                    & > a,
                    &.active a,
                    &.open a {
                        &:hover,
                        &:focus {
                            color: white;
                            background-color: @purple;
                        }
                    }

                    /* Resonsive desktop top navbar menu */
                    @media only screen and (max-width : 1330px) {
                        margin-right: 20px;
                        > a {
                            letter-spacing: 1px;
                            font-size: 1.8rem;
                        }
                    }

                    @media only screen and (max-width : 1100px) {
                        margin-right: 20px;
                        > a {
                            letter-spacing: 1px;
                        }
                    }

                    @media only screen and (max-width : 991px) {
                        margin-right: 0px;
                        > a {
                            letter-spacing: 0px;
                            font-size: 1.6rem;
                        }
                    }
                    

                }
            }

            &-icon-shopping {
                &-cart {
                    background: url('../img/shoppingcart.png') no-repeat center;
                    background-size: cover;
                    display: block;
                    width: 20px;
                    height: 20px;
                    position: relative;
                }

                &-indicator {
                    background: tomato;
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    color: white;
                    font-weight: normal;
                    text-align: center;
                    line-height: 20px;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;

                }
            }
        }

        .dropdown-menu {
            .dropdown-menu;
            
            &:not(.to-left) {
                right: auto !important;
                .dropdown-triangle(9px, 29px);
            }

            &.to-left {
                .dropdown-triangle(9px, 29px, right);
            }

            // multilevel menu
            .dropdown-submenu {
                position: relative;
                &:hover > .dropdown-menu {
                    display: block;
                }
                &:hover > a {
                    after {
                        border-left-color: #ffffff;
                    }
                }

                // hide triangles in nested sub-menu's
                .dropdown-menu {
                    &:before {display: none;}
                    &:after {display: none;}
                }

                &  > .dropdown-menu {
                    -moz-border-radius: 0 6px 6px;
                    -webkit-border-radius: 0 6px 6px 6px;
                    border-radius: 0 6px 6px 6px;
                    left: 100%;
                    margin-left: -1px;
                    margin-top: -6px;
                    top: 0;
                }

                & > a {
                    &:after {
                        border-color: transparent;
                        border-left-color: #cccccc;
                        border-style: solid;
                        border-width: 5px 0 5px 5px;
                        content: " ";
                        display: block;
                        float: right;
                        height: 0;
                        margin-right: -10px;
                        margin-top: 3px;
                        width: 0;
                    }
                }

                &.pull-left {
                    float: left;

                    & > .dropdown-menu {
                        -moz-border-radius: 6px 0 6px 6px;
                        -webkit-border-radius: 6px 0 6px 6px;
                        border-radius: 6px 0 6px 6px;
                        left: -100%;
                        margin-left: 10px;
                    }
                }
            }

            &.to-left {
                .dropdown-submenu {
                    & > a {
                        &:after {
                            border-left-color: transparent;
                            border-right-color: #cccccc;
                            border-width: 5px 5px 5px 0px;
                            float: left;
                            margin-left: -12px;
                        }
                    }

                    .dropdown-menu {
                        left: auto;
                        right: 100% !important;
                    }
                }
            }
        }

        &-logo {
            max-width: 175px;
            background: url("@{base}/img/logo-purple.png") center no-repeat;
            width: 175px;
            height: 37px;
            background-size: contain;
        }

        &-top {
            font-size: 1.4rem;
            float: right;
            margin-top: 26px;

            @media only screen and (min-width : 991px) {
                margin-right: 25px;
            }
            
            @media only screen and (min-width : 1330px) {
                margin-right: 35px;
            }


            > li {
                margin-left: 13px;
                padding: 0;

                &:last-child {
                    margin-left: 25px;
                }
            }

            a {
                color: @text-color;
                text-transform: uppercase;
                font-size: 1.5rem;
                font-weight: 500;
                letter-spacing: 0.5px;
            }

            &-flag {}

            &-arrow {
                font-size: 16px;
                font-weight: bold;
                position: relative;
                padding-left: 4px;
                top: 2px;
            }

            .dropdown {
                cursor: pointer;
                z-index: 1002;

                &-toggle {
                    user-select: none;
                }

                &-menu {
                    margin-top: 12px;
                    right: -8px;

                    li {
                        a {
                            text-transform: none;
                            .flag {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-mobile {
        padding: 35px 0 25px;

        &-row {}

        &-logo {
            display: block;
            background: url("@{base}/img/logo-purple.png") center no-repeat;
            background-size: contain;
            width: 292px;
            height: 62px;
        }

        &-nav-burger {
            @burger-width: 60px;
            @burger-height: 4px;

            float: right;
            height: 40px;
            width: @burger-width;
            cursor: pointer;
            position: relative;
            top: 15px;

            i {
                position: relative;
                display: inline-block;
                width: @burger-width;
                height: @burger-height;
                text-transform: uppercase;
                text-indent:-55px;
                background: @primary;
                transition: all .2s ease-out;

                &:before, &:after {
                    content:'';
                    width: @burger-width;
                    height: @burger-height;
                    background: @primary;
                    position: absolute;
                    left: 0;
                    transition: all .2s ease-out;
                }
                
                &:before { top: -@burger-height - 12px; }
                &:after { bottom: -@burger-height - 12px; }
            }

            &:hover {
                i:before { top: -10px; }
                i:after { bottom: -10px; }

            }

            &.active {
                i { background: #fff; }
                i:before {
                    top:0;
                    transform: rotateZ(45deg);
                }
                i:after {
                    bottom:0;
                    transform: rotateZ(-45deg);
                }
            }
        }
    }
}


.page-template-homepage .header-navigation{

    // hide shadows for desktop
    @media only screen and (min-width : 768px) {
        &:after{display: none;}
    }

    &-desktop {
        position: absolute;
        width: 100%;
        z-index: 2;


        .navbar {
            &-nav {
                & > li {

                    & > a {
                        color: white;
                    }

                    &.dropdown.open > a{
                        background-color: inherit;
                        color: darken(white, 20%)
                    }
                }
            }

            .fa {
                height: 19px;
            }
        }

        .header-navigation-desktop-logo {
            background-image: url("@{base}/img/logo-white.png");
        }

        .header-navigation-desktop-top {
            > li {
                > a {
                    color: white;
                }
            }

            &-arrow {
                color: white;
            }
        }
    }
}