.brsModal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                padding: 10px 15px;

                .close {
                    font-size: 3rem;
                }

                h4 {
                    text-transform: none;
                    font-weight: 500;
                }
            }

            .modal-body {
                padding: 40px;

                h4:first-child {
                    margin-top: 0px;
                }
            }
        }
    }
}