.mm-menu-prevent-jump:not(.mm-menu) {
    display: none;
}

.mm-menu {
    background: @purple;

    .mm-navbar {
        height: 60px;

        border-color: white;
        & > *, a {
            color: white;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 20px;
            padding: 20px 0;
            height: 60px;
        }
        
        .mm-btn:before,
        .mm-btn:after {
            border-color: @primary;
        }
    }


    .mm-listview {
        margin-top: 26px !important;

        a, span {
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            text-align: center;
        }

        a.mm-next.mm-fullsubopen + span,
        a.mm-next.mm-fullsubopen + a {
            padding-right: 10px;
        }

        li:not(.mm-divider):after {
            border-width: 0;
        }
        li{
            margin-bottom: 6px;
        }

        li .mm-next:after,
        li .mm-arrow:after {
            border-color: @primary;
        }
    }
}