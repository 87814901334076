#handle_portlet_-519 {
    text-align: center;

    .list {
        width: 100%;

        .dottedlink {
            width: 210px;
            padding-left: 1px;
            padding-right: 1px;
        }
    }

    .textboldnolink {
        padding-bottom: 35px;
    }

    .dottedlink {
        margin-bottom: 12px;
        .button;
    }
}

#address {
    input.nlbutton,
    input.bgbutton {
        padding: 10px 20px;
        margin-right: 10px;
    }
}

.listheadertextb {
    font-size: 12px;
}
.listtexthl, .listtexthl a.dottedlink, .listtexthlctr a.dottedlink, .listtexthlrt {
    font-size: 10pt !important;
}

#page-preloader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 100500;
}

#page-preloader .spinner {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    background: url('/c.3293628/site/v2/img/spinner.gif') no-repeat 50% 50%;
    margin: -16px 0 0 -16px;
}