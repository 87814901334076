/* Inner shadow */
.box-shadow(@insert, @x: 0, @y: 0, @blur: 10px, @color: #666) when (@insert = true) {
    -webkit-box-shadow: inset @x @y @blur @color;
                    box-shadow: inset @x @y @blur @color;
}

/* Outer shadow */
.box-shadow(@insert, @x: 0, @y: 0, @blur: 10px, @color: #666) when (@insert = false) {
    -webkit-box-shadow: @x @y @blur @color;
                    box-shadow: @x @y @blur @color;
}

.background-gradient(@from: #000, @to: #fff) {
    background-color: @from;
    background-image: -webkit-gradient(linear, left top, left bottom, from(@from), to(@to));
    background-image: -webkit-linear-gradient(top, @from, @to);
    background-image:    -moz-linear-gradient(top, @from, @to);
    background-image:      -o-linear-gradient(top, @from, @to);
    background-image:         linear-gradient(to bottom, @from, @to);
}

.button-background(@main-color: #d5652c, @secondary-color: darken(@main-color, 6%)) {
    background-color: @main-color;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
        background-color: darken(@main-color, 10%);
    }
    &:active {
        .box-shadow(true, 1px, 1px, 10px, darken(@main-color, 20%));
    }
}

/* .customer-item(@columns: 4, @column-offset: 8px) {
    @width-percent: 1 / @columns * 100;
    @width-offset: @columns / (@columns - 1);
    @width: calc(~'@{width-percent}% - @{column-offset} / @{width-offset}');

    // nasty browsers fallback
    width: @width-percent - 1%;
    
    width: @width;
    
    margin-right: @column-offset;
    &:nth-child(@{columns}n) { margin-right: 0; }
} */


/**
 * height aspect ratio
 *
 * 75% = 4:3
 * 100% = 1:1
 * 200% = 1:2
 * 50% = 2:1
 *
 * Important: MUST contain inner element with class *-inner
 * 
 * read more
 * http://www.mademyday.de/css-height-equals-width-with-pure-css.html
 */
/* .height-aspect-ratio(@ratio: 100%) {
    position: relative;

    &:after {
        content: '';
        display: block;
        position: relative;
        margin-top: @ratio;
    }

    &-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
} */

/**
 * Make bootstrap grid row columns same height
 * http://stackoverflow.com/a/19695851/1221082
 */
.vertical-align-middle-all-columns(@columns: 2) {
    display: table;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    & > div[class^="col-"] {
        display: table-cell;
        float: none;
        vertical-align: middle;
        width: 100% / @columns;
    }
}

/**
 * Triangle arrow for bootsrap's dropdown
 * @topOffset - margin from top
 * @leftOffset - margin from left or right (determined by 3rd arguments)
 * @leftOrRight - offset from side
 */
.dropdown-triangle(@topOffset: 9px, @leftOffset: 29px, @leftOrRight: left) {
    @borderTopOffset: @topOffset - 1;
    @borderLeftOffset: @leftOffset + 1;
    &:before {
        position: absolute;
        top: -@topOffset;
        @{leftOrRight}: @leftOffset;
        display: inline-block;
        border-right: @topOffset solid transparent;
        border-bottom: @topOffset solid #ccc;
        border-left: @topOffset solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    &:after {
        position: absolute;
        top: -@borderTopOffset;
        @{leftOrRight}: @borderLeftOffset;
        display: inline-block;
        border-right: @borderTopOffset solid transparent;
        border-bottom: @borderTopOffset solid #ffffff;
        border-left: @borderTopOffset solid transparent;
        content: '';
    }
}


/**
 * Styles for Twitter Bootstrap navbar dropdown
 */
.dropdown-menu() {
    border-radius: 6px !important;
    border: 1px solid #c6c6c6;
    margin-top: 3px;
    padding-top: 9px;
    padding-bottom: 7px;

    & > li {
        &:not(:last-child) {
            border-bottom: 1px solid #f2f2f2;
        }

        &.active {
            & > a {
                background: fade(@purple, 80%);
            }
        }

        & > a {
            text-transform: uppercase;
            padding: 13px 26px 12px 19px;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1;

            & > i {
                color: @primary;
                line-height: 0;
                padding-left: 4px;
            }

            &:hover {
                background: @purple;
                color: white;
                line-height: 1;

                & > i {
                    font-weight: bold;
                }
            }
        }

    }
}

.placeholder(@c: gray) {
    @color: fade(@c, 65%);
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    @color;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color:    @color;
       opacity:  1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
       color:    @color;
       opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
       color:    @color;
    }
}