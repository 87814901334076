a {
    color: @primary;

    &.quotes {
        &:after {
            content: '»';
            padding-left: 3px;
            font-size: 1.1em;
            line-height: 1em;
        }
    }

    &:hover,
    &:focus {
        color: darken(@primary, 10%);
        text-decoration: none;

    }

    &.black { color: #000; }
    &.white { color: @text-color; }
}