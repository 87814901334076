.breadcrumb {
    color: @text-color;
    background: inherit;
    margin: 0;
    padding: 19px 0 12px;
    font-size: 1.4rem;

    > li + li:before {
        content: '»';
        color: @text-color-alt;
    }

    > .active a {
        color: @text-color-alt;
    }
}