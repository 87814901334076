.ext-gecko, .ext-webkit {
    overflow: auto !important;

    .titlebar .pt_container {

        div {
            background: none;
            border: none;
        }

        .pt_title {
            position: static;
            padding-top: 35px;
            padding-bottom: 20px;
            .textboldnolink;
        }
    }

    .uir_list_header {
        #div__label {
            width: auto !important;

            #div__labtab {
                table-layout: auto !important;
            }
        }

        #div__labend {
            display: none;
        }
    }

    .uir-listpage-body {
        height: auto !important;
        border: none !important;

        #div__bodytab {
            td {
                background: none;
            }

            td.listheadertdleft,
            td.listheadertd {
                border: none;
            }
        }

        .listtexthlctr {

            input[type="text"].input {
                padding: 1px;
                height: auto;
                text-align: center;
                margin-bottom: 0;
                font-size: 1.1rem;
            }

            .bgbutton {
                margin: 0;
                padding: 5px 10px;
                font-size: 1.2rem;
            }
        }
    }

    form#body_actions {
        .dottedlink {
            font-size: 1.1rem;
            font-weight: normal;
        }
    }


    form#footer_actions_form, form#main_form, form#main_form + table, td.uir-secondary-buttons {

        padding-top: 15px;

        table.uir_top_footer_row,
        table.uir_list_buttonbar_left {
            display: none;
        }

        #print,
        #new,
        #edit,
        #makecopy,
        #resetter,
        #submitter,
        #submitnew,
        #submitcopy,
        #cancel,
        #saveprint,
        #saveemail,
        #secondarysaveprint,
        #secondarysaveemail,
        #secondaryresetter,
        #secondarysubmitter,
        #secondarymakecopy,
        #secondarysubmitnew,
        #secondarysubmitcopy,
        #secondaryedit,
        #secondarynew,
        #secondaryprint {
            background: none;
            .button;
            margin-bottom: 33px;
        }

        #tdrightcap_print,
        #tdleftcap_print,
        #tdrightcap_new,
        #tdleftcap_new,
        #tdrightcap_edit,
        #tdleftcap_edit,
        #tdrightcap_cancel,
        #tdleftcap_cancel,
        #tdrightcap_makecopy,
        #tdleftcap_makecopy,
        #tdrightcap_resetter,
        #tdrightcap_submitter,
        #tdrightcap_submitnew,
        #tdrightcap_submitcopy,
        #tdleftcap_resetter,
        #tdleftcap_submitter,
        #tdleftcap_submitnew,
        #tdleftcap_submitcopy,
        #tdleftcap_saveprint,
        #tdrightcap_saveprint,
        #tdleftcap_saveemail,
        #tdrightcap_saveemail,
        #tdleftcap_secondarysaveprint,
        #tdrightcap_secondarysaveprint,
        #tdleftcap_secondarysaveemail,
        #tdrightcap_secondarysaveemail,
        #tdrightcap_secondaryresetter,
        #tdleftcap_secondaryresetter,
        #tdrightcap_secondarysubmitter,
        #tdrightcap_secondarymakecopy,
        #tdrightcap_secondarysubmitnew,
        #tdrightcap_secondarysubmitcopy,
        #tdleftcap_secondarysubmitter,
        #tdleftcap_secondarymakecopy,
        #tdleftcap_secondarysubmitnew,
        #tdleftcap_secondarysubmitcopy,
        #tdrightcap_secondaryedit,
        #tdrightcap_secondarynew,
        #tdrightcap_secondaryprint,
        #tdleftcap_secondaryedit,
        #tdleftcap_secondarynew,
        #tdleftcap_secondaryprint {
            display: none;
        }

        #tdbody_print,
        #tdbody_new,
        #tdbody_edit,
        #tdbody_cancel,
        #tdbody_makecopy,
        #tdbody_resetter,
        #tdbody_submitter,
        #tdbody_submitnew,
        #tdbody_submitcopy,
        #tdbody_saveprint,
        #tdbody_saveemail,
        #tdbody_secondarysaveprint,
        #tdbody_secondarysaveemail,
        #tdbody_secondaryresetter,
        #tdbody_secondarysubmitter,
        #tdbody_secondarymakecopy,
        #tdbody_secondarysubmitnew,
        #tdbody_secondarysubmitcopy,
        #tdbody_secondaryedit,
        #tdbody_secondarynew,
        #tdbody_secondaryprint{
            background: none;
        }

        #div__footer {
            padding-bottom: 120px;
        }

        .bntBgB {
            #btn_multibutton_export {
                height: 21px;
            }
        }

        .bntBgT {
          margin-top: 0;
        }

        input.checkbox {
            visibility: hidden;
        }

        img.checkboximage {
            margin-bottom: 18px;
        }

        .uir-fieldgroup-content td.smalltextnolink,
        .uir-fieldgroup-content span.smalltextnolink{
            white-space: normal !important;
        }
    }

    .uir_list_header,
    .uir-listpage-body,
    .titlebar .pt_container,
    form#body_actions,
    form#footer_actions_form,
    form#main_form,
    form#main_form + table,
    td.uir-secondary-buttons > table{
        margin-right: auto !important;
        margin-left: auto !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: 768px) {
        .uir_list_header,
        .uir-listpage-body,
        .titlebar .pt_container,
        form#body_actions,
        form#footer_actions_form,
        form#main_form,
        form#main_form + table,
        td.uir-secondary-buttons{
            width: 750px !important;
        }
    }
    @media (min-width: 992px) {
        .uir_list_header,
        .uir-listpage-body,
        .titlebar .pt_container,
        form#body_actions,
        form#footer_actions_form,
        form#main_form,
        form#main_form + table,
        td.uir-secondary-buttons{
            width: 970px !important;
        }
    }
    @media (min-width: 1320px) {
        .uir_list_header,
        .uir-listpage-body,
        .titlebar .pt_container,
        form#body_actions,
        form#footer_actions_form,
        form#main_form,
        form#main_form + table,
        td.uir-secondary-buttons{
            width: 1260px !important;
        }
    }

    #detail_table_lay {
        div.fgroup_title {
            color:#5A6F8F;
            border-bottom:1px solid #CCC;
            font-weight:600;
            white-space:nowrap;
            margin:33px 0 2px 0 !important;
            font-size: 2rem;
        }

        .fwmultisel, .fwmultisel_focus, .fwpopupsel, .fwpopupsel_focus {
            margin-top: -13px;
            margin-left: -8px;
            visibility: hidden;
        }

        #custrecord_capabilities_customer_display,
        #custrecord_vm_customer_display{
            width: 260px;
        }

        .i_dropdownarrow {
            margin-top: -16px;
            margin-left: -8px;
        }

        input.input, input.inputreq, input[type="text"], input[type="password"] {
            height: 20px;
            font-size: 1.2rem;
        }
        td.smalltextnolink {
            padding-left: 20px;
        }

        a.smalltextul,
        a.smalltextnolink {
            font-size: 1.2rem;
        }

        .smalltextnolink{
            line-height: 1.5rem;
        }
    }

    #applications_form, #apply_main_form, #credit_main_form, #payment_form, #deposit_main_form {
        input.input, input.inputreq, input[type="text"], input[type="password"] {
            height: 20px;
            font-size: 1.2rem;
        }

        .i_dropdownarrow {
            margin-top: -16px;
            margin-left: -8px;
        }

        td.smalltextnolink {
            padding-left: 20px;
        }

        a.smalltextul,
        a.smalltextnolink {
            font-size: 1.2rem;
        }

        .smalltextnolink{
            line-height: 1.5rem;
        }

        .bgbutton, .nlbutton, input[type="button"] {
            margin-top: 35px;
            color: black !important;
            text-align: center;
            font-size: 1.2rem;
            line-height: 1;
            font-weight: 500;
            background-color: transparent;
        }
    }

    .pt_title {
        text-align: left;
        padding-bottom: 20px;
        padding-top: 10px;
        font-family: inherit;
        font-weight: 400;
        line-height: 1.1;
        color: #2f0d6e;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 3.6rem;
    }

    .pt_body {
        border-bottom: 0;
        border-top: 0;
        height: 0;
        padding-right: 0;
    }

    .pt_head, .pt_end {
        width: 0;
        height: 0;
        background-image: none;
        outline: none;
        position: absolute;
    }
}

.ext-webkit {
  form#footer_actions_form,
  form#main_form,
  form#main_form + table, td.uir-secondary-buttons{
    #print,
    #new,
    #edit,
    #cancel,
    #makecopy,
    #resetter,
    #submitter,
    #submitnew,
    #submitcopy,
    #saveprint,
    #saveemail,
    #secondarysaveprint,
    #secondarysaveemail,
    #secondaryresetter,
    #secondarysubmitter,
    #secondarymakecopy,
    #secondarysubmitnew,
    #secondarysubmitcopy,
    #secondaryedit,
    #secondarynew,
    #secondaryprint {
        height: 56px;
    }
  }
}

.bntRB, .bntRT, .bntLB, .bntLT {
  height: 10px;
}

.bglt {
    .text {
        font-size: 1.8rem;
        padding-left: 15px;
        padding-top: 17px;
    }
    .textboldnolink {
        font-size: 1.8rem;
    }
}

.bgofftab, #tbl_goback {
    margin-top: 10px;
    background-color: transparent;

    #cancel, #goback {
        margin-top: 0;
        padding: 1px 6px 0;
        font-size: 8pt;
        font-weight: 100;
        color: #222222 !important;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
    }
}