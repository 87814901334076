.slick{
    &-slider{
        .slick-slide{
            outline: 0;
        }
    }

    &-arrow{
        position: absolute;
        top: 50%;
        display: block;
        width: 34px;
        height: 86px;
        border-width: 0;
        font-size: 0;
        z-index: 2;
        outline: 0;
    }
    &-prev{
        left: 0;
        background: url("@{base-img}/slick-left.png");
    }
    &-next{
        right: 0;
        background: url("@{base-img}/slick-right.png");
    }
    &-dots{
        margin: 0;
        margin-top: 40px;
        padding: 0;
        text-align: center;
        li {
            display: inline-block;
            margin: 0 10px;
            button {
                border: 1px solid #292057;
                font-size: 0;
                background: transparent;
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            &.slick-active {
                button {
                    background: #292057;
                }
            }
        }
    }
    &-mobile-content-helper {

    }

    &-nav-squares {
        .slick-dots {
            margin-top: 20px;
            
            li {
                button {
                    border-radius: 0;
                    border: 0;
                    background: #9aa4af;
                    width: 18px;
                    height: 18px;
                }

                &.slick-active {
                    button {
                        background: @purple;
                    }
                }
            }
        }
    }
}