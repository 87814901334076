.about-us {
    padding-top: 36px;
    padding-bottom: 41px;
    font-size: 1.8rem;
    background: #f4f4f4;
    h2 {
        color: @text-color;
        padding-bottom: 2px;
    }
    p {
        line-height: 1.7;
        margin-bottom: 19px;
    }

    @media only screen and (max-width : 767px) {
        padding-bottom: 52px;
        h2 {
            font-size: 3.076rem;
            padding-bottom: 7px;
        }
        p, a {
            font-size: 2.461rem;
            line-height: 1.6;
        }
        .row {
            & > div:nth-child(2) {
                padding-top: 42px;
            }
        }
    }
}