@speed: 0.3s;

.store {
    &-item {
        transition: box-shadow @speed ease;
        
        img {
            max-height: 180px;
            max-width: 95%;
        }
        
        &-buy {
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
            padding: 20px;
            background: white;
            
            transition: visibility 0s ease @speed, box-shadow @speed ease;

            &-wrapper {
                visibility: hidden;
                position: absolute;
                overflow: hidden;
                left: -20px;
                right: -20px;
                z-index: 1;
            }
        }

        .button {
            opacity: 0;
            transition: opacity @speed ease;
        }

        &-amount {
            position: relative;
            border: 1px solid #ccc;
            border-radius: 3px;
            display: inline-block;
            margin-right: 20px; 
            vertical-align: top;
            height: 60px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            opacity: 0;
            transition: opacity @speed ease;

            &-button {
                display: inline-block;
                vertical-align: top;
                height: 58px;
                width: 30px;
                text-align: center;
                line-height: 58px;
                color: darken(#ccc, 10%);
                cursor: pointer;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    background: darken(#fff, 15%);
                    top: 5px;
                    bottom: 5px;
                    width: 1px;
                }

                &:hover {
                    background: darken(#fff, 4%);
                }
                &:active {
                    background: darken(#fff, 8%);
                    color: darken(#ccc, 30%);
                }
            }

            .store-item-amount-minus:before {
                right: 0;
            }

            .store-item-amount-plus:before {
                left: 0;
            }

            input.store-item-amount-input {
                width: 40px;
                height: 58px;
                margin-bottom: 0;
                border: 0;
                text-align: center;
                line-height: 60px;
                padding: 1px;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        &:hover {
            box-shadow: 0 0 15px 0px rgba(0,0,0, .4), 0 0 1px 0 #ccc;

            .store-item-buy {
               box-shadow: 0 0 15px 0px rgba(0,0,0, .4), 0 0 1px 0 #ccc;
               transition-delay: 0s;
            }
            .store-item-buy-wrapper { 
                visibility: visible;
                z-index: 3;
            }

            .button, .store-item-amount {
                opacity: 1;
            }
        }
    }
}