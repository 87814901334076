.header {
    &-intro {
        padding-top: 47px;
        padding-bottom: 40px;
        min-height: 300px;
        background-position: center;
        background-size: cover;
        position: relative;
        text-align: center;
        color: white;
        &:before {
            content: '';
            background: url("@{base}/img/intro-pattern.png");
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }

        h1 {
            font-size: 52px;
            line-height: 1.3;
            color: inherit;
        }

        p {
            font-size: 2.2rem;
            line-height: 1.55;
            padding-top: 4px;
        }

        &-length {
            margin-top: 20px;
            font-size: 18px;
        }

        &-arrows {
            position: absolute;
            bottom: 20px;
            bottom: 2vmin;
            left: 0;
            right: 0;
            font-size: 6rem;
            opacity: .2;
        }
    }

    @media only screen and (max-width : 767px) {
        &-intro {
            padding-top: 35px;
            padding-bottom: 58px;
            &-headline {
                line-height: 1.25;
            }
            
            p {
                padding-top: 9px;
                font-size: 2.769rem;
                line-height: 1.47;
            }
        }
    }

    &-darken {
        background: #d2dde8;
        text-align: center;

        h1 {
            margin: 30px 0;
        }
    }
}