.content,
.brsModal .modal-body {

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            padding-left: 10px;
            text-indent: -10px;

            &:before {
                content: '-';
                margin-right: 5px;
            }
        }
    }

    ul, ol {
        font-size: 1.8rem;
        li {
            margin-bottom: 10px;

            i.fa {
                padding-left: 5px;
            }
        }
    }

    p {
        font-size: 1.8rem;
        line-height: 1.7;
        margin-bottom: 20px;

        &.action {
            margin-top: -10px;
            margin-bottom: 30px;
        }
    }

    a {
        font-weight: 500;

        &.cta {
            font-size: 1.8rem;
            text-transform: uppercase;
            white-space: nowrap;
        }

        i {
            color: @purple;
            padding-right: 6px;
        }
    }

    @media only screen and (max-width : 767px) {
        h2 {
            font-size: 3.6rem;
        }

        p, ul li, ol li {
            font-size: 2.5rem;
        }
    }


}